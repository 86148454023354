import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Avatar,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import { getProfile, updateProfile } from "../redux/authSlice";
const formatDateToISO = (dob) => {
  if (!dob) return ""; // Nếu dob là null hoặc chuỗi rỗng, trả về chuỗi rỗng
  const [day, month, year] = dob.split("/"); // Tách dd, mm, yyyy
  return `${year}-${month}-${day}`; // Trả về định dạng yyyy-mm-dd
}; // Hàm chuyển đổi từ yyyy-mm-dd sang dd/mm/yyyy
const formatISOToDate = (isoDate) => {
  if (!isoDate) return "";
  const [year, month, day] = isoDate.split("-"); // Tách yyyy-mm-dd thành 3 phần
  return `${day}/${month}/${year}`; // Định dạng lại thành dd/mm/yyyy
};

export default function ProfileUser() {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [open, setOpen] = useState(false); // điều khiển mở/đóng
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isAvatarChanged, setIsAvatarChanged] = useState(false); // Track if avatar is changed
  const [error, setError] = useState("");
  const profileUser = useSelector((state) => state.auth.user);

  const AccountID = profileUser?.profile.AccountID;
  const check = profileUser?.role == "student" ? 0 : 1;
  const {
    profile = [],
    loading,
    error: profileError,
  } = useSelector((state) => state.profile);
  // console.log("profileUser", profile);

  useEffect(() => {
    const tokens = {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    };

    dispatch(getProfile({ tokens, accountId: AccountID }));
  }, [dispatch, AccountID]);

  // URL ảnh đại diện mặc định nếu ProfileImage là null
  const defaultProfileImage = "/path-to-default-avatar.jpg"; // Thay đường dẫn với ảnh mặc định của bạn

  const [originalName, setOriginalName] = useState(""); //lưu tên ban đầu
  const [name, setName] = useState(""); // lưu tên
  const [email, setEmail] = useState(""); // lưu email
  const [dob, setDob] = useState(""); // lưu Ngày Sinh

  useEffect(() => {
    if (profile?.[0]) {
      // Kiểm tra an toàn sự tồn tại của profile[0]
      const fullName = `${profile[0]?.firstName || ""} ${profile[0]?.lastName || ""}`;
      setName(fullName);
      setOriginalName(fullName); // Lưu tên ban đầu
      setEmail(profileUser?.profile?.Email);
      setDob(profile[0]?.dob ? formatDateToISO(profile[0]?.dob) : "");

      // Cải tiến việc gán avatar
      const profileImageSrc = profile[0].profileImage
        ? profile[0].profileImage.startsWith("data:image/")
          ? profile[0].profileImage // Nếu đã có tiền tố "data:image", không thay đổi
          : `data:image/png;base64,${profile[0].profileImage}` // Thêm tiền tố base64 nếu thiếu
        : defaultProfileImage; // Sử dụng ảnh mặc định nếu không có base64

      setAvatar(profileImageSrc);
    }
  }, [profile]); // Chạy lại mỗi khi profile thay đổi
  // Regex pattern for special characters
  const specialCharsPattern = /[;'"|\/`\\]/g;

  // Validate username and DOB
  const validateForm = () => {
    if (!name || name.trim() === "") {
      setError("Username cannot be empty");
      setSnackbarOpen(true);
      return false;
    }
    if (specialCharsPattern.test(name)) {
      setError("Username contains invalid characters");
      setSnackbarOpen(true);
      return false;
    }
    if (!dob || dob.trim() === "") {
      setError("Date of birth cannot be empty");
      setSnackbarOpen(true);
      return false;
    }
    return true;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // File type validation: Only allow images
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (file && !validTypes.includes(file.type)) {
      toast.error("Invalid file type. Please upload a JPG or PNG.");
      return;
    }

    // File size validation: Limit file size to 5MB
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxFileSize) {
      toast.error(
        "File is too large. Please upload an image smaller than 5MB."
      );
      return;
    }

    // Convert file to base64 and update the avatar state
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Image = reader.result;
      setAvatar(base64Image); // Set the avatar to the base64 string
      setIsAvatarChanged(true); // Mark avatar as changed
    };

    // Read the file as a Data URL (base64)
    if (file) {
      const base64data = reader.result;
      setAvatar(base64data);
      localStorage.setItem("userAvatar", base64data);
      reader.readAsDataURL(file); // This triggers the conversion to base64
      setSelectedFile(file); // Store the selected file
    }
  };
  const handleSaveAvatar = () => {
    if (isAvatarChanged) {
      if (!name.trim()) {
        toast.error(`Please enter your name.`);
        setSnackbarOpen(false);
        return;
      }

      // Tách fullName thành firstName và lastName
      const nameParts = name.trim().split(" ");
      const firstName = nameParts[0]; // Lấy phần đầu tiên làm firstName
      const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : ""; // Nếu có phần sau, lấy làm lastName

      let Image = avatar.split(",")[1];

      // Khởi tạo đối tượng baseBody với thông tin cần thiết
      let baseBody = {
        image: Image,
        firstName: firstName,
        lastName: lastName,
        dob: formatISOToDate(dob),
        accountId: AccountID,
      };

      // Thêm thông tin studentId hoặc teacherId tùy vào role

      // Lấy tokens từ localStorage
      const tokens = {
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
      };

      try {
        // Gửi action updateProfile
        dispatch(updateProfile({ tokens, body: baseBody, check }));

        // Thông báo thành công
        toast.success("Avatar saved successfully!");
        // dispatch(getProfile({ tokens, accountId: AccountID }));
      } catch (error) {
        // Xử lý lỗi nếu có
        console.error("Error updating Avatar:", error);
        toast.error("Failed to update Avatar. Please try again.");
      }

      // Reset trạng thái avatar đã thay đổi
      setIsAvatarChanged(false);
    } else {
      toast.error("No new avatar selected.");
    }
  };

  //dob
  const handleDOBChange = (e) => {
    const selectedDate = e.target.value;
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);
    // Hàm thay đổi ngày sinh

    // Check if selected date is in the future
    if (selectedDateObj > currentDate) {
      toast.error("Date of Birth cannot be in the future.");
      return;
    }
    if (
      selectedDateObj.getMonth() === 1 && // February
      selectedDateObj.getDate() === 29 && // 29th
      !isLeapYear(selectedDateObj.getFullYear()) // Check if not a leap year
    ) {
      toast.error("February can only have 29 days in a leap year.");
      return;
    }
    // Set the DOB if valid
    setDob(selectedDate);
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const handleEditClick = () => {
    setOriginalName(name); // Lưu tên gốc khi bắt đầu chỉnh sửa
    setOpen(true); // Mở khi nhấn nút edit
  };

  const handleClose = () => {
    setName(originalName); // Khôi phục tên về giá trị ban đầu
    setOpen(false);
  };

  const handlePasswordDialogOpen = () => {
    setOpenPasswordDialog(true); // Mở mk
  };

  const handlePasswordDialogClose = () => {
    setOpenPasswordDialog(false); // Đóng mk
  };

  const handleSave = () => {
    if (validateForm()) {
      if (!name.trim()) {
        toast.error(`Please enter your name.`);
        setSnackbarOpen(false);
        return;
      }

      // Tách fullName thành firstName và lastName
      const nameParts = name.trim().split(" ");
      const firstName = nameParts[0]; // Lấy phần đầu tiên làm firstName
      const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : ""; // Nếu có phần sau, lấy làm lastName
      let Image = avatar.split(",")[1];
      let baseBody = {
        image: Image,
        firstName: firstName,
        lastName: lastName,
        dob: formatISOToDate(dob),
        accountId: AccountID,
      };

      console.log("Save:", baseBody);

      // Lấy tokens từ localStorage
      const tokens = {
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
      };

      try {
        // Gửi action updateProfile
        dispatch(updateProfile({ tokens, body: baseBody, check }));

        // Thông báo thành công
        toast.success("Update profile successfully!");
        // dispatch(getProfile({ tokens, accountId: AccountID }));
      } catch (error) {
        // Xử lý lỗi nếu có
        console.error("Error updating profile:", error);
        toast.error("Failed to update profile. Please try again.");
      }

      // Để lưu thay đổi (tên, email, mật khẩu, dob)
      setOriginalName(name);

      setOpen(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };

  if (loading) return <CircularProgress />;
  // if (profileError) return <Alert severity="error">{profileError}</Alert>;

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 8,
        padding: 3,
        borderRadius: "8px",
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Profile User
      </Typography>

      <Grid container spacing={2}>
        {/* Phần bên trái cho Avatar */}
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }} // Ẩn input
              id="avatar-upload"
              onClick={(e) => {
                e.target.value = null; // Đặt lại giá trị input để cho phép tải lên lại cùng một tệp
              }}
            />
            <label htmlFor="avatar-upload">
              <Avatar
                sx={{ width: 120, height: 120, mb: 2, cursor: "pointer" }}
                alt="Avatar User"
                src={avatar}
              />
            </label>
            <Typography variant="h6" align="center">
              Avatar
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                mt: 2,
                transition: "background-color 0.3s, color 0.3s",
                "&:hover": {
                  backgroundColor: "rgb(159, 117, 197)",
                  color: "#fff",
                  borderColor: "#1976d2",
                },
              }}
              onClick={handleSaveAvatar}
            >
              <SendAndArchiveIcon style={{ marginRight: "5px" }} />
              Save Avatar
            </Button>
          </Box>
        </Grid>

        {/* Phần bên phải cho Thông Tin Hồ Sơ */}
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6" align="center">
              Profile
            </Typography>
            <TextField
              margin="normal"
              label="Username"
              disabled
              fullWidth
              variant="outlined"
              value={name} // Hiển thị tên người dùng
              InputProps={{
                readOnly: true, // Làm cho trường tên chỉ đọc
              }}
            />
            <TextField
              margin="normal"
              label="Email"
              disabled
              fullWidth
              variant="outlined"
              value={email} // Hiển thị email
              InputProps={{
                readOnly: true, // Làm cho trường email chỉ đọc
              }}
            />
            <TextField
              margin="normal"
              label="DOB"
              disabled
              fullWidth
              variant="outlined"
              value={dob} // Hiển thị ngày sinh
              InputProps={{
                readOnly: true, // Làm cho trường dob chỉ đọc
              }}
            />
            <div style={{ display: "flex" }}>
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  mt: 2,
                  transition: "background-color 0.3s, color 0.3s",
                  "&:hover": {
                    backgroundColor: "rgb(159, 117, 197)",
                    color: "#fff",
                    borderColor: "#1976d2",
                  },
                }}
                onClick={handleEditClick}
              >
                <ModeEditIcon sx={{ mr: 1 }} />
                Edit
              </Button>
              {/* <Button
                variant="outlined"
                color="secondary"
                sx={{
                  mt: 2,
                  ml: 2,
                  transition: "background-color 0.3s, color 0.3s",
                  "&:hover": {
                    backgroundColor: "rgb(159, 117, 197)",
                    color: "#fff",
                    borderColor: "#1976d2",
                  },
                }} // Khoảng cách bên trái
                onClick={handlePasswordDialogOpen} // đổi mật khẩu
              >
                <ChangeCircleIcon sx={{ mr: 1 }} />
                Change Password
              </Button> */}
            </div>
          </Box>
        </Grid>
      </Grid>

      {/* popup cho edit */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)} // Xử lý nhập tên
            error={Boolean(error)}
            helperText={error && error}
          />
          <TextField
            margin="dense"
            label="Email"
            disabled
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Xử lý nhập email
          />
          <TextField
            margin="dense"
            label="DOB"
            type="date"
            fullWidth
            variant="outlined"
            value={dob}
            onChange={handleDOBChange} // Use the new handler
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split("T")[0], // Set max date to today
            }}
            // error={Boolean(error)}
            // helperText={error && error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* popup đổi mật khẩu */}
      <Dialog open={openPasswordDialog} onClose={handlePasswordDialogClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordDialogClose} color="primary">
            Cancel
          </Button>
          <Button color="primary">Change</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}
