import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { loginWithToken } from "../redux/authSlice";
import { logoutUser } from "../redux/authSlice"; // Import action để logout
import { toast } from "react-toastify"; // Import toast

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const savedTokens = {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    };

    if (savedTokens.accessToken && savedTokens.refreshToken) {
      dispatch(loginWithToken(savedTokens))
        .unwrap()
        .then((response) => {
          const { accessToken, refreshToken } = response.tokens;

          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          setIsLoading(false);
        })
        .catch((error) => {
          // Thông báo lỗi cho người dùng
          toast.error(error.message || "Invalid tokens. Please log in again.");

          // Xóa token khỏi localStorage
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("persist:auth");

          // Gọi action để logout người dùng
          dispatch(logoutUser());

          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, navigate, location]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
