import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StudentRoutes from "./routes/StudentRoutes";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TeacherRoutes from "./routes/TeacherRoutes";
import NotFound from "./pages/NotFound";
import Forbidden from "./pages/Forbidden";
import ServerError from "./pages/ServerError";
import ProtectedRoute from "./utils/ProtectedRoute";
import DefaultRoutes from "./routes/DefaultRoutes";
import "./App.css";
import AdminRoutes from "./routes/AdminRoutes";
import RefereeRoutes from "./routes/RefereeRoutes";
function App() {
  const user = useSelector((state) => state.auth.user);
  const userRole = user?.role || "student";

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* Các route có điều kiện hoặc với các quyền truy cập khác */}
        <Route path="/*" element={<DefaultRoutes />} />
        <Route
          path="/student/*"
          element={
            <ProtectedRoute>
              {userRole === "student" ? <StudentRoutes /> : <Forbidden />}
            </ProtectedRoute>
          }
        />
        <Route
          path="/teacher/*"
          element={
            <ProtectedRoute>
              {userRole === "teacher" ? <TeacherRoutes /> : <Forbidden />}
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              {userRole === "admin" ? <AdminRoutes /> : <Forbidden />}
            </ProtectedRoute>
          }
        />
        <Route
          path="/referee/*"
          element={
            <ProtectedRoute>
              {userRole === "referee" ? <RefereeRoutes /> : <Forbidden />}
              {/* <RefereeRoutes /> */}
            </ProtectedRoute>
          }
        />

        {/* Các trang lỗi */}
        <Route path="/403" element={<Forbidden />} />
        <Route path="/500" element={<ServerError />} />

        {/* 404 Not Found sẽ phải là route cuối cùng */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
